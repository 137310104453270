import { useContext, useMemo } from 'react'
import { useTheme, useMediaQuery } from '@hermes/web-components'
import { useSpecialistPracticesContacts } from './useSpecialistPractices'
import { useVisibilityComponents } from './useVisibilityComponents'
import { useTopLevelKeywords } from './useKeywordsMapper'
import useTranslateMessage from './useTranslateMessage'
import { Address, ProfileKeyword, SpecialistProfile } from '../types'
import { Breadcrumb } from '../types/breadcrumbs'
import { BookingSlot } from '../types/booking'
import { capitalise } from '../utils/strings'
import useBreadCrumbsSchema from './useBreadcrumbsSchema'
import { AppDataContext } from '../providers/AppData'
import useStatistic from './useStatistic'
import useSpecialistSchema from './useSpecialistSchema'
import { useGetContactsData } from './useGetProfileData'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { SearchTypes } from '../providers/SearchProfilesPage/types'
import { useSpecialistPracticesWithAddress } from './useSpecialistPracticesWithAddress'
import getCountryByLocale from '../utils/getCountryByLocale'

type SpecialistPageConfigHookProps = {
  specialist: SpecialistProfile
  bookingSlots: BookingSlot[]
  totalReviews: number
  topKeyword?: ProfileKeyword
  topLocation?: {
    label: string
    slug: string
  }
  isUnclaimed: boolean
  breadcrumbs?: Breadcrumb[]
  address?: Address
}

// todo: fix complexity
// eslint-disable-next-line complexity
export const useSpecialistPageConfig = ({
  specialist,
  breadcrumbs,
  isUnclaimed,
  topKeyword,
  topLocation,
  bookingSlots,
  totalReviews,
  address
}: SpecialistPageConfigHookProps) => {
  const { language, locale } = useContext(AppDataContext)
  const theme = useTheme()
  const specSchema = useSpecialistSchema(specialist)
  const translate = useTranslateMessage()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))
  const { newPersistentPanelEnabled } = useFeatureFlags()

  const profileVideo = specialist.media?.filter((media) => media.type === 'profile' && media.enabled)[0]
  const profileName = useMemo(() => {
    let profileName = ''
    if (specialist.title[language]) {
      profileName = specialist.title[language] + ' '
    }
    return profileName + specialist.fullName[language]
  }, [language, specialist.fullName, specialist.title])

  const visibilityComponents = useVisibilityComponents({ specialist, address })
  const introVideoAvailable = useMemo(
    () => Boolean(!isUnclaimed && visibilityComponents.introductionVideoAvailable && profileVideo),
    [isUnclaimed, profileVideo, visibilityComponents.introductionVideoAvailable]
  )
  const publications = specialist.media?.filter((media) => media.type === 'publication' && media.enabled)
  const specialistTitle = specialist.title?.[language]?.length > 1 ? specialist.title[language] : undefined
  const specialistSuffix = specialist.suffix?.[language]?.length > 1 ? specialist.suffix[language] : undefined
  const contacts = useGetContactsData({ specialistData: specialist })

  const lastBreadcrumb = breadcrumbs?.at(-1)
  const lastBreadcrumbLocation = lastBreadcrumb
    ? capitalise(decodeURIComponent(decodeURI(lastBreadcrumb.label || language)))
    : ''

  const defaultSpecialistLabel = lastBreadcrumb
    ? translate('search.breadcrumb_location', {
        name:
          topKeyword?.id === 1
            ? translate('search.all_specialists')
            : (topKeyword?.practitioner
                ? `${topKeyword?.practitioner?.[language] || topKeyword?.practitioner?.en}s`
                : topKeyword?.name[language]) || '',
        location: capitalise(decodeURIComponent(decodeURI(lastBreadcrumb.label || language)))
      })
    : ''

  const getBreadcrumbLabel = () => {
    if (specialist.type === SearchTypes.healthAndSocialCare) {
      return translate('search.breadcrumb_location', {
        name: translate('home-care-spec'),
        location: lastBreadcrumbLocation
      })
    }

    return defaultSpecialistLabel
  }

  const specialistBreadcrumb = lastBreadcrumb
    ? {
        label: getBreadcrumbLabel(),
        url: lastBreadcrumb.url,
        id: lastBreadcrumb.id
      }
    : undefined

  const mappedBreadcrumbs =
    breadcrumbs?.length && specialistBreadcrumb ? [...breadcrumbs.slice(0, -1), specialistBreadcrumb] : []

  const pageSchema = useBreadCrumbsSchema(specSchema, mappedBreadcrumbs)

  const { practicesWithContactDetails } = useSpecialistPracticesContacts(specialist.practices)

  const { reviewStatistic, peerRecommendationStatistic, mergedStatistic } = useStatistic(specialist.statistic)

  const isBookingAvailable = !!bookingSlots.length

  const showBookButton = contacts.some((contact) => contact.externalBookingLink?.length)
  const showEmailButton = contacts.some((contact) => contact.email?.length)
  const showPhoneButton = contacts.some((contact) => contact.phone?.length)
  const onlineBookingAvailable = !!bookingSlots?.length
  const alternativeBookAppointmentVisibilityCondition = !showBookButton && !onlineBookingAvailable

  const hideBookAppointmentButton = newPersistentPanelEnabled ? alternativeBookAppointmentVisibilityCondition : false

  const hideBookAppointment = !showBookButton && !showEmailButton && !showPhoneButton

  const keywords = useTopLevelKeywords(specialist.keywords || [])

  const topKeywordName = topKeyword?.name[language].toLowerCase() || ''

  const maxTopKeywordLength = (() => {
    if (language === 'de') {
      return 10
    } else {
      return 20
    }
  })()

  const topKeywordLengthIsTooLong = topKeywordName.length > maxTopKeywordLength

  const otherSpecialistsButtonText = translate(
    topKeywordLengthIsTooLong ? 'other.specialists.buttonText.withoutKeyword' : 'other.specialsts.buttonText',
    {
      keyword: topKeywordLengthIsTooLong ? '' : topKeywordName,
      location: ''
    }
  )

  const otherPracticesButtonText = translate(
    topKeywordLengthIsTooLong ? 'other.practices.buttonText.withoutKeyword' : 'other.practices.buttonText',
    {
      keyword: topKeywordLengthIsTooLong ? '' : topKeywordName,
      location: ''
    }
  )

  const urlKeyword = topKeyword?.slug?.[0] || 'all'
  const defaultCountry = getCountryByLocale(locale)
  const otherSpecialistSearchUrl = `/${locale}/find/${urlKeyword}/${topLocation?.slug ?? defaultCountry}/specialists`
  const otherPracticesSearchUrl = `/${locale}/find/${urlKeyword}/${topLocation?.slug ?? defaultCountry}/practices`

  const { filteredPractices } = useSpecialistPracticesWithAddress(specialist.practices)

  const profileAnalytics = {
    profile: {
      prf_type: specialist.plan,
      prf_practice_count: filteredPractices.length,
      prf_book_button: isBookingAvailable,
      prf_contact_email: showEmailButton,
      prf_contact_phone: showPhoneButton,
      prf_booking_link: showBookButton,
      prf_media_centre: visibilityComponents.media,
      prf_uuid: specialist.externalId,
      prf_specialty: keywords,
      prf_practice_type: undefined,
      // false for now as described in doc
      prf_facilities: false
    },
    review: {
      rvw_count: specialist.reviewsTotal?.toString() || '0',
      vw_score: specialist.averageRating?.toString() || '0'
    }
  }

  const sortedLanguages = useMemo(() => {
    return specialist.languages
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .sort((a, b) => {
        if (a.isoCode === language) {
          return -1
        }
        if (b.isoCode === language) {
          return 1
        }
        return 0
      })
  }, [language, specialist.languages])

  const bookingCalendarSpecialistProfile = useMemo(
    () => ({
      id: specialist.id,
      externalId: specialist.externalId,
      name: specialist.fullName[language],
      rating: specialist.averageRating,
      reviewsNumber: totalReviews,
      specialty: keywords,
      title: specialist.title[language],
      imageSrc: specialist.images.logo,
      pageLink: `/${locale}/specialist/${specialist.slug}`
    }),
    [
      keywords,
      language,
      locale,
      specialist.averageRating,
      specialist.externalId,
      specialist.fullName,
      specialist.id,
      specialist.images.logo,
      specialist.slug,
      specialist.title,
      totalReviews
    ]
  )

  const bookingCalendarSpecialistPractices = useMemo(
    () =>
      specialist.practices.map((specialistPractice) => ({
        id: specialistPractice.id,
        name: specialistPractice.name?.[language],
        rating: specialistPractice.reviews.overallExperience,
        reviewsNumber: specialistPractice.reviews?.reviewsTotal ?? 0,
        contactNumber: specialistPractice.contactDetailsPractice?.phone
      })),
    [language, specialist.practices]
  )

  const bookingCalendarSlots = useMemo(
    () =>
      specialist.practices.map((specialistPractice) => ({
        practiceId: specialistPractice.id,
        slots: bookingSlots.filter((f) => f.practice.id === specialistPractice.id)
      })),
    [specialist.practices, bookingSlots]
  )

  const bookingCalendarVisitReasons = useMemo(
    () => [
      { name: 'Select', value: null },
      ...specialist.keywords.map((keyword) => ({
        name: keyword.name?.[language],
        value: String(keyword.id)
      })),
      { name: 'Other', value: 'other' }
    ],
    [language, specialist.keywords]
  )

  return {
    isMobile,
    isLaptop,
    profileVideo,
    profileName,
    visibilityComponents,
    introVideoAvailable,
    publications,
    specialistTitle,
    specialistSuffix,
    contacts,
    mappedBreadcrumbs,
    pageSchema,
    practicesWithContactDetails,
    reviewStatistic,
    peerRecommendationStatistic,
    mergedStatistic,
    hideBookAppointment,
    hideBookAppointmentButton,
    otherSpecialistsButtonText,
    otherPracticesButtonText,
    otherSpecialistSearchUrl,
    otherPracticesSearchUrl,
    profileAnalytics,
    sortedLanguages,
    bookingCalendarSpecialistProfile,
    bookingCalendarSpecialistPractices,
    bookingCalendarSlots,
    bookingCalendarVisitReasons
  }
}
