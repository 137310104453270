import { useMemo } from 'react'
import { SpecialistPractice } from '../types'

export const useSpecialistPracticesWithAddress = (specialistPractices?: SpecialistPractice[]) =>
  useMemo(() => {
    if (!specialistPractices) {
      return {
        filteredPractices: []
      }
    }

    return {
      filteredPractices: specialistPractices.filter((practice) => !!practice.address?.googlePlaceId)
    }
  }, [specialistPractices])
